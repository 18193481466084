import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CooperationBanner, CooperationSectionWrapper, GetApp } from './cooperation.styles';
import { DriverApplyFormSection } from '../../../../components/layout/DriverApplyModal/driver-apply-form/DriverApplyForm.section';

// @ts-ignore
import Tilt from 'react-tilt';
import { AboveTitle, Badge, FlexWrapper, Img, RegularLink, Text, VozziButton } from '../../../../global/new-global-components';

export const CooperationSection = (props: any) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { side, backgroundImage, mobileBackgroundImage } = props;
  const [appStore, setAppStore] = useState('/vectors/app-store.svg');

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  return (
    <CooperationSectionWrapper>
      <DriverApplyFormSection show={open} onHide={hideModal} />
      <GetApp
        className="cooperation-section section"
        backgroundImage={backgroundImage}
        mobileBackgroundImage={mobileBackgroundImage}
        style={{ justifyContent: side === 'left' ? 'flex-start' : 'flex-end' }}
      >
        <Tilt options={{ max: 1, scale: 1, easing: 'cubic-bezier(.03,.98,.52,.99)', perspective: 400 }}>
          <CooperationBanner>
            <AboveTitle color="white" fontSize="20px" marginBottom="24px" textAlign="left">
              {t('partnership_CooperationSectionSubheading')}
            </AboveTitle>
            <Text
              className="cooperation-title"
              color="white"
              textAlign="left"
              fontWeight="600"
              marginBottom="20px"
              fontSize="32px"
              maxWidth="800px"
            >
              {t('partnership_CooperationSectionHeading')}
            </Text>
            <Text className="company-name" fontSize="16px" opacity="0.8" marginBottom="50px" color="white">
              {t('partnership_CooperationSectionText')}
            </Text>
            <FlexWrapper className="cta-button-flex-wrapper" justifyContent="flex-start">
              <VozziButton onClick={() => showModal()} className="mobile-left">
                <Img src="/vectors/arrow-right-white.svg" />
                {t('partnership_CooperationSectionCTAButton')}
              </VozziButton>
              <Badge className="badge" margin="0px">
                <Img style={{ height: 20, position: 'relative', marginRight: 10 }} src="/vectors/call.svg" />{' '}
                {t('partnership_CooperationSectionPhoneLabel')}
              </Badge>
            </FlexWrapper>
          </CooperationBanner>
        </Tilt>
      </GetApp>
    </CooperationSectionWrapper>
  );
};
