import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql } from 'gatsby';
import { COUNTRY } from '../../configs/env';
import { countryLangs } from '../../configs/country-langs';
import { getFeaturedImgForBlogLayout } from '../../helpers/getFeaturedImgForBlogLayout';

import { HomePageLayout } from '../../layouts/HomePage.layout';
import HeroSection from './sections/new_hero_section/hero.section';
import ServiceSection from './sections/new_service_section/service.section';
import { GetAppSection } from '../../pages_/home/new_get_app_section/getapp.section';
import TextWithCtaButtonSection from './sections/text_with_cta_button_section/text-with-cta.section';
import { TestimonialsSection } from './sections/new_testimonials_section/testimonials.section';
import { SpecialPartnersSection } from './sections/new_special_partners_section/special-partners.section';
import { CooperationSection } from './sections/new_cooperation_section/cooperation.section';
import { WpFeedDataPropsI, WpPostNodeI } from 'types/WpFeedData';
import { BlogPostI } from 'pages_/blog/BlogPost.interface';
import { LanguageGuard } from '../../components/layout/LanguageGuard/LanguageGuard';

const PartnershipPage = (props: WpFeedDataPropsI): React.FC | ReactElement => {
  const { t, i18n } = useTranslation();

  const LANG = i18n.language;

  const metaOgImg = `/img/meta-img/partnership-meta-og-2-0-new.jpeg`;

  const meta = {
    title: t('meta_PartnershipTitle'),
    description: t('meta_PartnershipDescription'),
  };

  const og = {
    image: metaOgImg,
  };

  const returnWpPostsByCountry = (edge: WpPostNodeI) => {
    if (COUNTRY === 'rs') {
      return edge.node.country === COUNTRY || !edge.node.country;
    } else {
      return edge.node.country === COUNTRY;
    }
  };

  const cLangs: any = countryLangs;

  const posts: Array<BlogPostI> = props.data.allWordpressPost.edges
    .filter((edge: WpPostNodeI) => edge.node.categories.filter((category) => category.slug === 'promo').length > 0)
    .filter((edge: WpPostNodeI) => returnWpPostsByCountry(edge))
    .filter((edge) => edge.node.tags && edge.node.tags.find((tag) => tag.name.toUpperCase() === 'VULCO'))
    .map((edge: WpPostNodeI) => {
      return {
        id: edge.node.id,
        title: edge.node.title,
        excerpt: edge.node.excerpt,
        featuredImg: getFeaturedImgForBlogLayout(edge.node),
        date: edge.node.date,
        url: `/${cLangs[COUNTRY]}/${t('promo_Route')}/${edge.node.slug}`,
        categories: edge.node.categories,
      };
    });

  return (
    <HomePageLayout footerTopSection={false} fullWidth={true} meta={meta} og={og}>
      <HeroSection />
      <ServiceSection />

      <TestimonialsSection />

      <GetAppSection
        iosLink={t('link_ios-driver-app')}
        androidLink={t('link_android-driver-app')}
        huaweiLink={t('link_huawei-driver-app')}
        side="left"
        mobileBackgroundImage="/new-images/get-app-section-background-3-mobile.png"
        backgroundImage="/new-images/get-app-section-background-3.png"
        elementPosition={1}
      />

      <LanguageGuard visible={[{ country: 'rs' }]}>
        <TextWithCtaButtonSection
          aboveTitle={t('partnership_WantToSellSubHeading')}
          title={t('partnership_WantToSellHeading')}
          description={t('partnership_WantToSellText')}
        />
      </LanguageGuard>

      <LanguageGuard visible={[{ country: 'rs' }]}>
        <TextWithCtaButtonSection
          layout="block"
          textColor="white"
          backgroundImage="/new-images/nis-partnership.png"
          backgroundImageMobile="/new-images/nis-partnership-mobile.png"
          aboveTitle={t('partnership_NisSectionSubheading')}
          title={t('partnership_NisSectionHeading')}
          description={t('partnership_NisSectionText')}
          buttonText={t('partnership_NisSectionCTAButton')}
          buttonLink="/sr/promo/saradnja-vozzi-i-nis-programa-lojalnosti-sa-nama-na-putu"
          logoImage="/img/snnp-card.webp"
        />
      </LanguageGuard>

      {/* GRAWE is only in RS, we shall not show to the other countries
      so they wont be confused */}
      <LanguageGuard visible={[{ country: 'rs' }]}>
        <TextWithCtaButtonSection
          backgroundColor="#E9E9EB"
          aboveTitle={t('partnership_GraweSectionSubheading')}
          title={t('partnership_GraweSectionHeading')}
          description={t('partnership_GraweSectionText')}
          buttonText={t('partnership_GraweSectionCTAButton')}
          buttonLink="/sr/novosti/grawe-osiguranje/"
        />
      </LanguageGuard>

      <LanguageGuard visible={[{ country: 'rs' }, { country: 'ba' }, { country: 'mk' }, { country: 'hr' }]}>
        <SpecialPartnersSection posts={posts} />
      </LanguageGuard>

      <CooperationSection />
    </HomePageLayout>
  );
};

export const allPostsQuery = graphql`
  query {
    allWordpressPost {
      edges {
        node {
          id
          slug
          path
          title
          excerpt
          status
          date
          template
          country
          format
          tags {
            name
          }
          categories {
            slug
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default PartnershipPage;
