import React from 'react';
import { Field, Form, Formik } from 'formik';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { Link } from 'gatsby';
import { Box, Flex, Image, Text } from 'rebass';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { Modal } from '../../Modal/Modal.component';
import { COUNTRY } from '../../../../configs/env';

import { CustomErrorMessage } from '../../../functional/CustomErrorMessage';
import Dialog from '../../../functional/SweetAlert';
import { VozziPrimaryButton } from '../../_/button.component';
import { theme } from '../../../../layouts/main.layout';
import { buttonStyle, headingStyle, inputStyle, labelTextStyle } from '../../faqSendQuestionModal/FaqSendQuestionForm.css';

const subTitleStyle = css`
  color: #333333;
  font-size: ${theme.fontSizes[3]}px;
`;

interface DriverApplyFormProps {
  firstName: string;
  lastName: string;
  mobileNumber: string;
  email: string;
  companyName: string;
  companyIdNumber: string;
  address: string;
  city: string;
}

export const DriverApplyFormSection = ({ show, onHide }: any) => {
  const { t } = useTranslation();
  const handleSubmit = async (data: DriverApplyFormProps) => {
    try {
      const response = await axios.post('/driver-partnership-form', data);

      if (response) {
        Dialog.fire({
          title: t('response_Success'),
          icon: 'success',
          confirmButtonColor: theme.colors.vozziVividOrange,
        });
        onHide();
      }
    } catch (err) {
      Sentry.captureException(err);
      Dialog.fire({
        title: t('response_Error'),
        icon: 'error',
        confirmButtonColor: theme.colors.vozziVividOrange,
      });
    }
  };

  const initialValues = {
    firstName: '',
    lastName: '',
    mobileNumber: '',
    email: '',
    companyName: '',
    companyIdNumber: '',
    address: '',
    city: '',
    countryCode: COUNTRY,
  };

  const validationSchema = object().shape({
    firstName: string().required(t('validation_FirstName')),
    lastName: string().required(t('validation_LastName')),
    mobileNumber: string().required(t('validation_Mobile')),
    email: string().email(t('validation_ValidEmail')).required(t('validation_Email')),
    companyName: string().required(t('validation_CompanyName')),
    companyIdNumber: string().required(t('validation_CompanyIdNumber')),
    address: string().required(t('validation_Address')),
    city: string().required(t('validation_City')),
  });

  return (
    <Modal show={show} onHide={onHide}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
        {({ isValid }) => (
          <Form style={{ backgroundColor: 'white', borderRadius: 20, padding: '20px 0px' }}>
            <Flex mt={40} mx="auto" flexDirection="column" width={{ _: '80vw', xl: '80%' }} justifyContent="center">
              <Flex justifyContent="space-between" alignItems="center">
                <Text css={headingStyle}>{t('form_Heading')}</Text>

                <Box onClick={onHide} style={{ cursor: 'pointer' }}>
                  <Image style={{ height: 20 }} src="/new-images/close.png" />
                </Box>
              </Flex>

              <Text css={subTitleStyle}>{t('form_ContactInfo')}</Text>

              <Field css={inputStyle} id="firstName" name="firstName" placeholder={t('placeholder_FirstName')} />
              <CustomErrorMessage name="firstName" />

              <Field css={inputStyle} id="lastName" name="lastName" placeholder={t('placeholder_LastName')} />
              <CustomErrorMessage name="lastName" />

              <Flex mb={50}>
                <Flex flex={1} mr={2} flexDirection="column">
                  <Field css={inputStyle} id="mobileNumber" name="mobileNumber" placeholder={t('placeholder_Mobile')} />
                  <CustomErrorMessage name="mobileNumber" />
                </Flex>
                <Flex flex={1} flexDirection="column">
                  <Field css={inputStyle} name="email" placeholder={t('placeholder_Email')} />
                  <CustomErrorMessage name="email" />
                </Flex>
              </Flex>

              <Text css={subTitleStyle}>{t('form_CompanyInfo')}</Text>

              <Field css={inputStyle} id="companyName" name="companyName" placeholder={t('placeholder_CompanyName')} />
              <CustomErrorMessage name="companyName" />

              <Field css={inputStyle} id="companyIdNumber" name="companyIdNumber" placeholder={t('placeholder_CompanyId')} />
              <CustomErrorMessage name="companyIdNumber" />

              <Field css={inputStyle} id="address" name="address" placeholder={t('placeholder_Address')} />
              <CustomErrorMessage name="address" />

              <Field css={inputStyle} id="city" name="city" placeholder={t('placeholder_City')} />
              <CustomErrorMessage name="city" />

              <VozziPrimaryButton css={buttonStyle} mt={4} type="submit" disabled={!isValid}>
                {t('btn_BecomePartner')}
              </VozziPrimaryButton>

              <Text css={labelTextStyle} mb={4}>
                {t('form_Consent')}
                <Link to={t('link_termsAndConditions')} style={{ color: '#333333', fontWeight: 800 }}>
                  <br /> {t('form_PrivacyPolicy')}
                </Link>
              </Text>
            </Flex>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
