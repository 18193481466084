import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Blog, SpecialPartnersSectionWrapper } from './special-partners.styles';
import moment from 'moment';
import { AboveTitle, BadgeBlack, BadgeLightGray, FlexWrapperStart, Text, Title, Wrapper } from '../../../../global/new-global-components';
import Slider from 'react-slick';
import './special-partners.css';
import { Link } from 'gatsby';

export const SpecialPartnersSection = (props: any) => {
  const { posts } = props;
  const { t } = useTranslation();
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let slider1 = [] as any;
  let slider2 = [] as any;

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  posts.sort(function (a: string, b: string) {
    return new Date(b.date) - new Date(a.date);
  });

  const postComponents = posts.slice(0, 6).map((item: any, index: number) => {
    return (
      <div key={index} style={{ backgroundColor: 'white', width: '30%', flex: '0 0 30%' }}>
        <Link to={item.url}>
          <div style={{ maxHeight: 180, overflow: 'hidden' }}>
            <img src={item.featuredImg.src} />
          </div>
          <div className="slick-slide-title-wrapper" style={{ backgroundColor: 'white' }}>
            <FlexWrapperStart className="mobile-stay-flex">
              <BadgeBlack>{item.categories[0].slug}</BadgeBlack>
              <BadgeLightGray>{moment(item.date).format('DD.MM.YYYY')}</BadgeLightGray>
            </FlexWrapperStart>
            <h3 className="blog-title" dangerouslySetInnerHTML={{ __html: item.title }}></h3>
          </div>
        </Link>
      </div>
    );
  });

  const countPostComponents = postComponents.length;

  const arr = [];
  for (let i = 1; i < countPostComponents + 1; i++) {
    arr.push(i);
  }

  function Arrow(props: any) {
    const { className, onClick } = props;
    return <div className={className} onClick={onClick} />;
  }

  function PrevSvgArrow(props: any) {
    const { className, onClick } = props;
    return (
      <img
        style={{
          height: 54,
          width: 54,
          marginRight: '10px',
          opacity: onClick === null ? 0.25 : 1,
          cursor: onClick === null ? 'auto' : 'pointer',
        }}
        src="/img/slider-new-previous.svg"
        className={className}
        onClick={onClick}
      />
    );
  }

  function NextSvgArrow(props: any) {
    const { className, onClick } = props;

    return (
      <img
        style={{
          height: 54,
          width: 54,
          marginLeft: '10px',
          opacity: onClick === null ? 0.25 : 1,
          cursor: onClick === null ? 'auto' : 'pointer',
        }}
        src="/img/slider-new-next.svg"
        className={className}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    height: 500,
    arrows: true,
    variableWidth: true,
    centerMode: false,
    // initialSlide: 1,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <Arrow className="slick-arrow slick-prev" />,
    nextArrow: <Arrow className="slick-arrow slick-next" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settingsForPaginationSlider = {
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    infinite: false,
    arrows: true,
    focusOnSelect: true,
    variableWidth: false,
    prevArrow: <PrevSvgArrow />,
    nextArrow: <NextSvgArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          variableWidth: false,
          prevArrow: <PrevSvgArrow />,
          nextArrow: <NextSvgArrow />,
        },
      },
    ],
  };

  return (
    <SpecialPartnersSectionWrapper>
      <Blog className="blog-section section">
        <Wrapper
          className="mobile-padding-0 blog-section-title-wrapper special-partners-blog-section-title-wrapper"
          padding="60px 250px 70px 250px"
        >
          <AboveTitle marginBottom="20px" textAlign="left">
            {t('partnership_VulcoSectionSubheading')}
          </AboveTitle>
          <Title lineHeight="115%" textAlign="left" maxWidth="800px" marginBottom="20px">
            {t('partnership_VulcoSectionHeading')}
          </Title>
          <Text fontSize="20px" maxWidth="800px">
            {t('partnership_VulcoSectionText')}
          </Text>
        </Wrapper>
        <div className="blog-slider-wrapper">
          <Slider className="first-blog-slider" asNavFor={nav2 || undefined} ref={(slider) => (slider1 = slider)} {...settings}>
            {postComponents}
          </Slider>
        </div>

        <div className="blog-second-slider-wrapper vulcoArrowsWrapper" style={{ justifyContent: 'flex-end' }}>
          <Slider
            className="second-blog-slider vulcoArrows"
            asNavFor={nav1 || undefined}
            ref={(slider) => (slider2 = slider)}
            {...settingsForPaginationSlider}
          >
            {arr.map((item, index) => (
              <div key={index}>{item}</div>
            ))}
          </Slider>
        </div>
      </Blog>
    </SpecialPartnersSectionWrapper>
  );
};
