import styled from '@emotion/styled';

export type PropsType = {
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  padding?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  margin?: string;
  color?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  backgroundImageMobile?: string;
  border?: string;
  fontSize?: string;
  opacity?: string;
  height?: string;
  width?: string;
  display?: string;
  justifyContent?: string;
  alignItems?: string;
  flexWrap?: string;
  textAlign?: string;
  fontWeight?: string;
  maxWidth?: string;
  maxHeight?: string;
  overflow?: string;
  objectFit?: string;
  flexDirection?: string;
  position?: string;
};

export const TextWithCtaButtonSectionWrapper = styled.div`
  background-color: ${(props: PropsType) => props.backgroundColor || null};
  background-image: ${(props: PropsType) => `url(${props.backgroundImage})` || null};
  background-size: cover;

  .text-with-cta-button-section {
    padding: 100px 250px;
  }

  .snnp-logo {
    width: 250px;
  }

  @media screen and (min-width: 450px) and (max-width: 769px) {
    .text-with-cta-button-section {
      padding: 50px !important;
    }
  }

  @media screen and (min-width: 780px) and (max-width: 900px) {
    .text-with-cta-button-section {
      padding: 50px !important;
    }
  }

  @media screen and (min-width: 900px) and (max-width: 1100px) {
    .text-with-cta-button-section {
      padding: 100px 50px;

      h2 {
        max-width: 600px;
      }
    }
  }

  @media screen and (min-width: 1100px) and (max-width: 1399px) {
    .text-with-cta-button-section {
      padding: 100px 50px;

      h2 {
        max-width: 800px;
      }
    }
  }

  @media screen and (min-width: 1400px) {
    .text-with-cta-button-section {
      padding: 100px 100px;
    }
  }

  @media screen and (min-width: 1500px) {
    .text-with-cta-button-section {
      padding: 100px 150px;
    }
  }

  @media screen and (min-width: 1600px) {
    .text-with-cta-button-section {
      padding: 100px 200px;
    }
  }

  @media screen and (min-width: 2000px) {
    .text-with-cta-button-section {
      padding: 100px 200px;
      h2 {
        max-width: 1200px;
        font-size: 46px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .text-with-cta-button-section {
      padding: 20px;

      .service-flex-wrapper {
        border-radius: 10px;
      }

      .bundles-section-flex-wrapper {
        align-items: flex-start;
      }

      .snnp-logo-wrapper {
        align-items: flex-start;
      }

      .mobile-left {
        margin: 20px 0px;
      }

      img {
        max-width: 200px;
        margin: 0;
      }

      .flex-col {
        width: 100%;
        border-right: none;
      }

      .mobile-center {
        margin-left: -12px;
      }
    }
  }
`;
