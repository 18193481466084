import styled from '@emotion/styled';
import { COUNTRY } from '../../../../configs/env';

export const TestimonialsSectionWrapper = styled.div`
  .partnersArrows .slick-list {
    display: none !important;
  }

  .partnersArrows {
    justify-content: flex-end;
  }

  .partnersArrowsWrapper {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media screen and (max-width: 768px) {
    .testimonials-section-title {
      font-size: 28px;
      margin-bottom: 20px;
    }

    .testimonial-slide-title {
      font-size: 16px;
    }

    .testimonial-slide-text {
      font-size: 14px;
      margin-bottom: 20px;
    }

    .testimonial-slide-partner {
      font-size: 12px;
      margin-top: 75px;
    }

    .partnersArrows {
      width: unset;
    }
  }

  @media screen and (max-width: 370px) {
    .testimonials-section-title {
      font-size: 24px;
    }
  }

  @media screen and (min-width: 900px) and (max-width: 1100px) {
    .testimonial-slide-text {
      font-size: 14px;
    }

    .partners-testimonials-title {
      font-size: 32px;
    }
  }

  @media screen and (min-width: 1100px) and (max-width: 1399px) {
    .testimonial-slide-text {
      font-size: 16px;
    }

    .partners-testimonials-title {
      font-size: 32px;
    }
  }

  @media screen and (min-width: 1400px) {
    .partnersArrowsWrapper {
      padding-left: 100px;
      padding-right: 100px;
    }
  }

  @media screen and (min-width: 1500px) {
    .partnersArrowsWrapper {
      padding-left: 150px;
      padding-right: 150px;
    }
  }

  @media screen and (min-width: 1600px) {
    .partnersArrowsWrapper {
      padding-left: 200px;
      padding-right: 200px;
    }
  }

  @media screen and (min-width: 2000px) {
    .testimonials-section-title {
      font-size: 54px;

      font-weight: ${COUNTRY === 'mk' ? 600 : null};
    }

    .partnersArrowsWrapper {
      padding-left: 400px;
      padding-right: 400px;
    }
  }
`;

export const Blog = styled.div`
  height: auto;
  position: relative;
  background-color: #f4f4f4;
  background-size: 100% auto !important;
  height: auto;
  // padding: 280px 300px;
  .blog-slider-wrapper {
    padding: 0 0 300px 300px;
    padding-left: 300px;
    padding-bottom: 0;
  }
  /* .blog-title-wrapper {
    padding: 280px 300px;
    padding-bottom: 0;
  } */

  /* @media screen and (max-width: 1792px) {
    padding: 280px 280px 300px 300px;
    padding-bottom: 70px;
    .blog-slider-wrapper {
      padding: 0 0 220px 0;
      padding-left: 300px;
      padding-bottom: 0;
    }
    
  } */

  @media screen and (min-width: 450px) and (max-width: 769px) {
    .blog-section-title-wrapper {
      padding: 0px 30px 0px 30px !important;
    }
    .blog-slider-wrapper {
      padding-left: 30px !important;
    }

    .testimonial-slide-text {
      min-height: 100px !important;
    }

    .blog-second-slider-wrapper .second-blog-slider .slick-list {
      width: 200px !important;
      visibility: hidden !important;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    .blog-section-title-wrapper {
      padding: 60px 50px 70px 50px;
    }

    .blog-slider-wrapper {
      padding-left: 50px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 40px 0px 40px 20px;
    h2 {
      margin-bottom: 40px;
    }
    .blog-section-title-wrapper {
      flex-direction: row-reverse;
      padding-right: 20px !important;
    }
    .blog-second-slider-wrapper {
      padding-right: 20px;
      flex-direction: column-reverse;
    }
    .second-blog-slider {
      margin-top: 20px;
      width: 100% !important;
    }

    .partnersArrowsWrapper {
      padding-right: 50px;
    }
  }
`;
