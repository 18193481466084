import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { LanguageGuard } from '../../../../components/layout/LanguageGuard/LanguageGuard';
import { AboveTitle, FlexWrapper, Img, Text, Title, VozziButton, Wrapper } from '../../../../global/new-global-components';
import { TextWithCtaButtonSectionWrapper } from './text-with-cta.section.styles';

const TextWithCtaButtonSection = (props: any) => {
  const { t } = useTranslation();

  const {
    aboveTitle,
    title,
    titleFontWeight = '800',
    description,
    backgroundColor,
    backgroundImage,
    backgroundImageMobile,
    layout = 'default',
    textColor = 'black',
    buttonText = t('partnership_WantToSellCTAButton'),
    buttonLink = '',
    logoImage,
  } = props;

  return (
    <TextWithCtaButtonSectionWrapper
      backgroundImage={backgroundImage}
      backgroundImageMobile={backgroundImageMobile}
      backgroundColor={backgroundColor}
    >
      <Wrapper className="text-with-cta-button-section section">
        <FlexWrapper className="bundles-section-flex-wrapper">
          <Wrapper>
            {aboveTitle && (
              <AboveTitle color={textColor} textTransform="normal" textAlign="left" marginBottom="15px">
                {aboveTitle}
              </AboveTitle>
            )}
            <Title color={textColor} fontWeight={titleFontWeight} textAlign="left" maxWidth="700px" marginBottom="15px">
              {title}
            </Title>
            {description && (
              <Text color={textColor} maxWidth="600px" fontSize="20px">
                {description}
              </Text>
            )}
            {logoImage && layout === 'block' ? (
              <LanguageGuard visible={[{ lang: 'sr' }]}>
                <Link to={buttonLink}>
                  <VozziButton marginTop="20px" className="mobile-left">
                    <Img src="/vectors/arrow-right-white.svg" />
                    {buttonText}
                  </VozziButton>
                </Link>
              </LanguageGuard>
            ) : null}
          </Wrapper>
          {logoImage && layout === 'block' ? (
            <Img
              style={{ width: 250 }}
              className={`mobile-center ${logoImage && layout === 'inline' ? 'snnp-logo' : null}`}
              src={logoImage}
            />
          ) : null}
          {logoImage && layout === 'inline' ? (
            <Img className={`mobile-center ${logoImage && layout === 'inline' ? 'snnp-logo' : null}`} src={logoImage} />
          ) : null}
          {layout !== 'block' && buttonText && buttonLink && (
            <LanguageGuard visible={[{ lang: 'sr' }]}>
              <Link to={buttonLink}>
                <VozziButton className="mobile-left">
                  <Img src="/vectors/arrow-right-white.svg" />
                  {buttonText}
                </VozziButton>
              </Link>
            </LanguageGuard>
          )}
        </FlexWrapper>
        {logoImage && layout === 'default' ? (
          <FlexWrapper className="snnp-logo-wrapper" justifyContent="flex-end">
            <Img src={logoImage} />
          </FlexWrapper>
        ) : null}
      </Wrapper>
    </TextWithCtaButtonSectionWrapper>
  );
};

export default TextWithCtaButtonSection;
