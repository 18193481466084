import styled from '@emotion/styled';

export const SpecialPartnersSectionWrapper = styled.div`
  .blog-section {
    background-color: #f4f4f4;
  }

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    .blog-section-title-wrapper {
      padding: 60px 50px 70px 50px;
    }
  }

  @media screen and (min-width: 1400px) {
    .blog-section {
      .blog-section-title-wrapper {
        padding: 60px 100px 70px 100px;
      }
    }
  }

  @media screen and (min-width: 1500px) {
    .blog-section {
      .blog-section-title-wrapper {
        padding: 60px 150px 70px 150px;
      }
    }
  }

  @media screen and (min-width: 1601px) {
    .blog-section {
      .blog-section-title-wrapper {
        padding: 60px 250px 70px 250px;
      }
    }
  }

  @media screen and (min-width: 2000px) {
    .blog-section {
      .blog-section-title-wrapper {
        padding: 60px 200px 70px 200px;
      }
    }

    .special-partners-blog-section-title-wrapper {
      padding-left: 200px !important;
    }
  }
`;

export const Blog = styled.div`
  height: auto;
  position: relative;
  background-color: #e9e9eb;
  background-size: 100% auto !important;
  height: auto;
  // padding: 280px 300px;
  .blog-slider-wrapper {
    padding: 0 0 300px 300px;
    padding-left: 300px;
    padding-bottom: 0;
  }
  /* .blog-title-wrapper {
    padding: 280px 300px;
    padding-bottom: 0;
  } */

  @media screen and (min-width: 450px) and (max-width: 769px) {
    .blog-section-title-wrapper {
      padding: 60px 30px 70px 30px !important;
    }

    .blog-slider-wrapper {
      padding-left: 30px !important;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    .blog-section-title-wrapper {
      padding: 60px 50px 70px 50px;
    }

    .blog-slider-wrapper {
      padding-left: 50px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 40px 0px 40px 20px;
    h2 {
      margin-bottom: 40px;
    }
    .blog-section-title-wrapper {
      flex-direction: row-reverse;
      padding-right: 20px !important;
    }
    .blog-second-slider-wrapper {
      padding-right: 50px;
      flex-direction: column-reverse;
    }
    .second-blog-slider {
      margin-top: 20px;
      /* width: 100% !important; */
      width: 100%;
    }
  }
`;
