import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Blog, TestimonialsSectionWrapper } from './testimonials.styles';
import { Text, Wrapper } from '../../../../global/new-global-components';
import Slider from 'react-slick';
import './testimonials.css';

export const TestimonialsSection = () => {
  const { t } = useTranslation();
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let slider1 = [] as any;
  let slider2 = [] as any;

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  const posts = [
    {
      id: 1,
      title: t('partnership_ReviewsTitleOne'),
      content: t('partnership_ReviewsTextOne'),
      partner: t('partnership_ReviewsInfoOne'),
    },
    {
      id: 2,
      title: t('partnership_ReviewsTitleTwo'),
      content: t('partnership_ReviewsTextTwo'),
      partner: t('partnership_ReviewsInfoTwo'),
    },
    {
      id: 3,
      title: t('partnership_ReviewsTitleThree'),
      content: t('partnership_ReviewsTextThree'),
      partner: t('partnership_ReviewsInfoThree'),
    },
    {
      id: 4,
      title: t('partnership_ReviewsTitleFour'),
      content: t('partnership_ReviewsTextFour'),
      partner: t('partnership_ReviewsInfoFour'),
    },
    {
      id: 5,
      title: t('partnership_ReviewsTitleFive'),
      content: t('partnership_ReviewsTextFive'),
      partner: t('partnership_ReviewsInfoFive'),
    },
    {
      id: 6,
      title: t('partnership_ReviewsTitleSix'),
      content: t('partnership_ReviewsTextSix'),
      partner: t('partnership_ReviewsInfoSix'),
    },
  ];

  const postComponents = posts.map((item: any, index) => {
    return (
      <div key={index} style={{ backgroundColor: 'white', width: '30%', flex: '0 0 30%' }}>
        <div style={{ padding: '30px' }}>
          <Text className="testimonial-slide-title" fontSize="24px" marginBottom="30px" fontWeight="bold">
            {item.title}
          </Text>
          <Text className="testimonial-slide-text" minHeight="60px" maxHeight="60px" fontSize="16px" marginBottom="70px">
            {item.content}
          </Text>
          <Text className="testimonial-slide-partner" fontSize="16px" color="#333333">
            {item.partner}
          </Text>
        </div>
      </div>
    );
  });

  const countPostComponents = postComponents.length;

  const arr = [];
  for (let i = 1; i < countPostComponents + 1; i++) {
    arr.push(i);
  }

  function Arrow(props: any) {
    const { className, onClick } = props;
    return <div className={className} onClick={onClick} />;
  }

  function PrevSvgArrow(props: any) {
    const { className, onClick } = props;
    return (
      <img
        style={{
          height: 54,
          width: 54,
          marginRight: '10px',
          opacity: onClick === null ? 0.25 : 1,
          cursor: onClick === null ? 'auto' : 'pointer',
        }}
        src="/img/slider-new-previous.svg"
        className={className}
        onClick={onClick}
      />
    );
  }

  function NextSvgArrow(props: any) {
    const { className, onClick } = props;
    return (
      <img
        style={{
          height: 54,
          width: 54,
          marginLeft: '10px',
          opacity: onClick === null ? 0.25 : 1,
          cursor: onClick === null ? 'auto' : 'pointer',
        }}
        src="/img/slider-new-next.svg"
        className={className}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    height: 500,
    arrows: false,
    variableWidth: true,
    centerMode: false,
    // initialSlide: 1,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <Arrow className="slick-arrow slick-prev" />,
    nextArrow: <Arrow className="slick-arrow slick-next" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settingsForPaginationSlider = {
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    infinite: false,
    arrows: true,
    focusOnSelect: true,
    variableWidth: false,
    prevArrow: <PrevSvgArrow />,
    nextArrow: <NextSvgArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 610,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <TestimonialsSectionWrapper>
      <Blog className="blog-section section">
        <Wrapper className="mobile-padding-0 blog-section-title-wrapper" padding="60px 250px 30px 250px">
          <Text
            className="testimonials-section-title partners-testimonials-title"
            textAlign="left"
            fontSize="44px"
            marginBottom="0px"
            fontWeight="800"
          >
            {t('partnership_WhatPartnerSaysHeading')}
          </Text>
        </Wrapper>
        <div className="blog-slider-wrapper">
          <Slider className="first-blog-slider" asNavFor={nav2 || undefined} ref={(slider) => (slider1 = slider)} {...settings}>
            {postComponents}
          </Slider>
        </div>

        <div className="blog-second-slider-wrapper partnersArrowsWrapper" style={{ justifyContent: 'flex-end' }}>
          <Slider
            className="second-blog-slider partnersArrows"
            asNavFor={nav1 || undefined}
            ref={(slider) => (slider2 = slider)}
            {...settingsForPaginationSlider}
          >
            {arr.map((item, index) => (
              <div key={index}>{item}</div>
            ))}
          </Slider>
        </div>
      </Blog>
    </TestimonialsSectionWrapper>
  );
};
