import styled from '@emotion/styled';
import { COUNTRY } from '../../../../configs/env';

export type PropsType = {
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  padding?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  margin?: string;
  color?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  border?: string;
  fontSize?: string;
  opacity?: string;
  height?: string;
  width?: string;
  display?: string;
  justifyContent?: string;
  alignItems?: string;
  flexWrap?: string;
  textAlign?: string;
  fontWeight?: string;
  maxWidth?: string;
  maxHeight?: string;
  overflow?: string;
  objectFit?: string;
  flexDirection?: string;
  position?: string;
};

export const HeroSectionWrapper = styled.div`
  .break-on-md-and-lg {
    font-weight: 800;
  }

  @media screen and (min-width: 450px) and (max-width: 769px) {
    .flex-col-padding-20 {
      padding: 80px 50px !important;
    }
  }

  @media screen and (min-width: 780px) and (max-width: 900px) {
    .hero-section {
      flex-wrap: wrap;
      flex-direction: column;

      .hero-section-flex-col {
        padding-top: 60px;
        padding-bottom: 60px;
      }

      .flex-col {
        width: 100%;
      }

      .flex-col-img-wrapper {
        width: 100%;
      }
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    /* .hero-section-flex-col {
      height: calc(100vh - 94px);
    } */
    .hero-section-flex-col {
      padding: 0px 0px 0px 50px;
    }
  }

  @media screen and (min-width: 1400px) {
    .hero-section-flex-col {
      height: calc(100vh - 94px);
    }

    .hero-section-flex-col {
      padding: 0px 0px 0px 100px;
    }

    .flex-col-img-wrapper {
      height: calc(100vh - 94px);

      .img-wrapper {
        height: 100%;
      }

      img {
        height: 100%;
      }
    }
  }

  @media screen and (min-width: 1500px) {
    .hero-section-flex-col {
      padding: 0px 0px 0px 150px;
    }
  }

  @media screen and (min-width: 1600px) {
    .hero-section-flex-col {
      padding: 0px 0px 0px 200px;
    }
  }

  @media screen and (min-width: 2000px) {
    .hero-section-flex-col {
      padding: 0px 0px 0px 200px;

      h1 {
        font-weight: ${COUNTRY === 'mk' ? 400 : null};
      }

      p {
        font-size: 24px;
      }
    }

    .break-on-md-and-lg {
      font-weight: ${COUNTRY === 'mk' ? 600 : 800};
    }
  }

  @media screen and (max-width: 768px) {
    .badge {
      margin-top: 20px;
      font-size: 12px;
      margin-right: 5px;
    }

    .badge:last-child {
      margin-right: 0;
    }

    .badge img {
      display: none;
    }

    .flex-col {
      padding: 0px;
      display: block;
      width: 100%;
    }

    .flex-col-padding-20 {
      padding: 30px 20px;
    }

    p {
      font-size: 14px;
    }

    h1 {
      text-align: left;
      font-size: 40px;
    }

    .hero-section {
      padding: 0px;
      height: auto;
    }

    .mobile-hidden {
      display: none;
    }

    button {
      height: 50px;
      font-size: 16px;
      border-radius: 15px;
      margin-bottom: 40px;
    }

    .video-caption {
      height: 90px;

      button {
        border-radius: 50%;
        height: 50px;
        width: 50px;
        margin-bottom: 0;

        div {
          height: 37px;
          width: 37px;

          img {
            width: 10px;
          }
        }
      }
    }
  }
`;

export const VideoCaption = styled.div`
  height: 140px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: ${(props: PropsType) => props.position || 'static'};
  bottom: 0px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 40px;
  justify-content: space-between;
`;

export const PlayButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 70px;
  width: 70px;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 50%;
  justify-content: center;
`;

export const PlayButtonInner = styled.div`
  width: 54px;
  height: 54px;
  background-color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
