import { BigTitle, FlexCol, FlexWrapper, Img, ImgWrapper, Text, VozziButton } from '../../../../global/new-global-components';
import React, { useState } from 'react';
import { HeroSectionWrapper } from './hero.section.styles';
import { Trans, useTranslation } from 'react-i18next';
import { DriverApplyFormSection } from '../../../../components/layout/DriverApplyModal/driver-apply-form/DriverApplyForm.section';

const HeroSection = () => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  return (
    <HeroSectionWrapper>
      <DriverApplyFormSection show={open} onHide={hideModal} />
      <FlexWrapper backgroundColor="#f4f4f4" className="flex-col hero-section section">
        <FlexCol
          className="flex-col hero-section-flex-col flex-col-padding-20"
          padding="0px 0px 0px 250px"
          display="flex"
          justifyContent="center"
          alignItems="baseline"
          flexDirection="column"
          width="50%"
        >
          <BigTitle textAlign="left" margin="30px 0px">
            <Trans i18nKey={'partnership_Heading'}>
              part 1<span className="break-on-md-and-lg">part 2</span>
            </Trans>
          </BigTitle>
          <Text fontSize="24px" fontWeight="400" marginBottom="30px">
            {t('partnership_SubHeading')}
          </Text>
          <VozziButton onClick={() => showModal()} className="mobile-left">
            <Img src="/icons/arrow-right.svg" /> {t('partnership_CTAbutton')}
          </VozziButton>
        </FlexCol>
        <FlexCol className="flex-col-img-wrapper" style={{ transition: 'ease .3s' }} position="relative" width="50%">
          <ImgWrapper className="img-wrapper" position="relative">
            <Img objectFit="cover" width="100%" alt="img-new" src="/new-images/partnership-hero-2.png" />
          </ImgWrapper>
        </FlexCol>
      </FlexWrapper>
    </HeroSectionWrapper>
  );
};

export default HeroSection;
