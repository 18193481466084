import styled from '@emotion/styled';

export const CooperationSectionWrapper = styled.div`
  .cooperation-section {
    display: block;
  }

  .badge {
    font-size: 18px;
    display: flex;
    align-items: center;
    margin-left: 50px;
  }

  @media screen and (min-width: 450px) and (max-width: 769px) {
    .cooperation-section {
      padding: 50px !important;
    }

    .mobile-left {
      margin-bottom: 0 !important;
      margin-right: 15px;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    .cooperation-section {
      padding: 40px 50px;
    }
  }

  @media screen and (max-width: 768px) {
    h2 {
      font-size: 28px;
    }

    h4 {
      font-size: 12px;
    }

    .cooperation-section {
      padding: 40px 20px;
    }

    .cooperation-title {
      font-size: 28px;
    }

    .company-name {
      font-size: 14px;
    }

    .cta-button-flex-wrapper {
      flex-direction: row;
      justify-content: flex-start;

      .mobile-left {
        width: auto;
        margin-bottom: 20px;
      }

      .badge {
        margin-left: 0;
      }
    }

    button {
      width: 100%;
      height: 58px;

      img {
        height: 100%;
      }
    }
  }

  @media screen and (min-width: 1600px) {
    .cooperation-section {
      padding: 60px 200px;
    }
  }

  @media screen and (min-width: 2000px) {
    .cooperation-section {
      padding: 200px 400px;
    }
  }
`;

export const GetApp = styled.div<any>`
  height: auto;
  position: relative;
  background-color: #ffffff;
  background-size: 100% auto !important;
  height: auto;
  display: flex;
  align-items: center;
  padding: 80px 250px;
  background: ${(props: any) => `url(${props.backgroundImage}) no-repeat` || null};

  @media screen and (min-width: 1600px) {
    background-size: cover !important;
  }

  @media screen and (min-width: 1400px) {
    padding: 280px 100px;
    padding-bottom: 70px;
  }

  @media screen and (min-width: 1500px) {
    padding: 280px 150px;
    padding-bottom: 70px;
  }

  @media screen and (min-width: 1600px) {
    padding: 280px 250px;
    padding-bottom: 70px;
  }

  /* @media screen and (max-width: 1792px) {
    padding: 280px 250px;
    padding-bottom: 70px;
  } */
  @media screen and (max-width: 768px) {
    background: ${(props: any) => `url(${props.mobileBackgroundImage}) no-repeat` || null};
    background-size: 100% auto !important;
  }
`;

export const CooperationBanner = styled.div`
  width: 100%;
  height: auto;
  padding: 60px;
  background: #1e1e23;
  border-radius: 20px;
  @media screen and (max-width: 768px) {
    height: auto;
    width: 100%;
    padding: 40px 30px;
  }

  @media screen and (min-width: 1200px) {
    h2 {
      width: auto;
      max-width: 1000px;
    }
  }

  @media screen and (min-width: 1600px) {
    width: 100%;
    max-width: 100%;
    h2 {
      width: auto;
      max-width: 800px;
    }
  }

  @media screen and (min-width: 2000px) {
    height: auto;
    width: 100%;
    h2 {
      width: auto;
      max-width: 800px;
    }
  }
`;

export const GetAppAboveTitle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: white;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 12px;
`;

export const GetAppTitle = styled.h2`
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 125%;
  color: white;
`;

export const GetAppDownloadWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const GetAppDownloadBtn = styled.button`
  width: 166.64px;
  height: 58px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  margin-right: 20px;
  margin-top: 20px;
  border: 1px solid white;
  border-radius: 20px;

  && img {
    width: auto;
  }

  :hover {
    background-color: white;
  }

  :hover svg path {
    fill: #f47e20;
  }

  svg path {
    fill: white;
    transition: ease 0.3s;
  }

  @media screen and (max-width: 768px) {
    border-radius: 15px;
    margin-top: 10px;
    && img {
      width: 50%;
    }

    :hover {
      background-color: white;
    }

    :hover svg path {
      fill: #f47e20;
      transition: ease 0.3s;
    }

    :hover img {
    }
  }

  @media screen and (min-width: 2000px) {
    height: 70px;
    min-width: 200px;
  }
`;

export const GetAppDownloadBtnIcon = styled.img`
  width: 100%;
  height: auto;
  transition: ease 0.3s;
`;
