import {
  AboveTitle,
  FlexCol,
  FlexWrapper,
  Img,
  SmallTitle,
  Text,
  Title,
  VozziButton,
  Wrapper,
} from '../../../../global/new-global-components';
import React, { useState } from 'react';
import { ServiceSectionWrapper } from './service.section.styles';
import { Trans, useTranslation } from 'react-i18next';
import { DriverApplyFormSection } from '../../../../components/layout/DriverApplyModal/driver-apply-form/DriverApplyForm.section';
import { Link } from 'gatsby';

const ServiceSection = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  return (
    <ServiceSectionWrapper>
      <DriverApplyFormSection show={open} onHide={hideModal} />
      <Wrapper className="service-section section" backgroundColor="#E9E9EB">
        <FlexWrapper className="mobile-flex-start" alignItems="flex-end">
          <Wrapper>
            <AboveTitle textAlign="left" marginBottom="15px">
              {t('partnership_ApplySectionSubHeading')}
            </AboveTitle>
            <Title lineHeight="115%" textAlign="left" fontWeight="800">
              {/* Prijavite se, vrata naše <br className="mobile-hidden" />
              kompanije su Vam otvorena */}
              <Trans i18nKey="partnership_ApplySectionHeading">
                part 1<span className="break-on-md-and-lg">part 2</span>
              </Trans>
            </Title>
            <Text maxWidth="900px" opacity="0.8" marginTop="15px" fontSize="20px">
              {t('partnership_ApplySectionText')}
            </Text>
          </Wrapper>
          <VozziButton onClick={() => showModal()} className="mobile-left">
            <Img src="/icons/arrow-right.svg" /> {t('partnership_CTAbutton')}
          </VozziButton>
        </FlexWrapper>
        <FlexWrapper
          style={{ borderRadius: 20 }}
          className="service-flex-wrapper"
          margin="40px 0px"
          backgroundColor="white"
          flexWrap="wrap"
        >
          <FlexCol
            className="flex-col service-section-flex-wrapper"
            borderRight="1px solid #B3BAAD"
            borderBottom="1px solid #B3BAAD"
            padding="30px 60px"
            width="50%"
          >
            <Img width="100px" marginBottom="35px" src="/vectors/low_commision.svg" />
            <SmallTitle marginBottom="20px" textAlign="left" fontSize="24px">
              {t('partnership_DescriptionTitleOne')}
            </SmallTitle>
            <Text minHeight="165px" textAlign="left" fontSize="16px">
              {t('partnership_DescriptionTextOne')}
            </Text>
          </FlexCol>
          <FlexCol className="flex-col service-section-flex-wrapper" borderBottom="1px solid #B3BAAD" padding="30px 60px" width="50%">
            <Img width="100px" marginBottom="35px" src="/vectors/no_initial_investment.svg" />
            <SmallTitle marginBottom="20px" textAlign="left" fontSize="24px">
              {t('partnership_DescriptionTitleTwo')}
            </SmallTitle>
            <Text minHeight="165px" textAlign="left" fontSize="16px">
              {t('partnership_DescriptionTextTwo')}
            </Text>
          </FlexCol>
          <FlexCol
            className="flex-col service-section-flex-wrapper mobile-border-bottom"
            borderRight="1px solid #B3BAAD"
            padding="30px 60px"
            width="50%"
          >
            <Img width="100px" marginBottom="35px" src="/vectors/take_control_icon.svg" />
            <SmallTitle marginBottom="20px" textAlign="left" fontSize="24px">
              {t('partnership_DescriptionTitleThree')}
            </SmallTitle>
            <Text minHeight="165px" textAlign="left" fontSize="16px">
              <Trans i18nKey={'partnership_DescriptionTextThree'}>
                part1<Link to={t('link_codex')}>part2</Link>
              </Trans>
            </Text>
          </FlexCol>
          <FlexCol className="flex-col service-section-flex-wrapper" padding="30px 60px" width="50%">
            <Img width="100px" marginBottom="35px" src="/vectors/take_picture_icon.svg" />
            <SmallTitle marginBottom="20px" textAlign="left" fontSize="24px">
              {t('partnership_DescriptionTitleFour')}
            </SmallTitle>
            <Text minHeight="165px" textAlign="left" fontSize="16px">
              {t('partnership_DescriptionTextFour')}
            </Text>
          </FlexCol>
        </FlexWrapper>
      </Wrapper>
    </ServiceSectionWrapper>
  );
};

export default ServiceSection;
