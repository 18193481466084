import styled from '@emotion/styled';

export type PropsType = {
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  padding?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  margin?: string;
  color?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  border?: string;
  fontSize?: string;
  opacity?: string;
  height?: string;
  width?: string;
  display?: string;
  justifyContent?: string;
  alignItems?: string;
  flexWrap?: string;
  textAlign?: string;
  fontWeight?: string;
  maxWidth?: string;
  maxHeight?: string;
  overflow?: string;
  objectFit?: string;
  flexDirection?: string;
  position?: string;
};

export const ServiceSectionWrapper = styled.div`
  .service-section {
    padding: 100px 250px;
  }

  @media screen and (max-width: 370px) {
    .service-section-flex-wrapper {
      padding: 35px 20px;
    }
  }

  @media screen and (min-width: 450px) and (max-width: 769px) {
    .service-section {
      padding: 50px 50px !important;
    }
  }

  @media screen and (min-width: 780px) and (max-width: 900px) {
    .service-section-flex-wrapper {
      padding: 30px;
      p {
        min-height: 250px;
      }
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    .service-section {
      padding: 100px 50px;
    }
  }

  @media screen and (min-width: 1400px) {
    .service-section {
      padding: 100px 100px;
    }
  }

  @media screen and (min-width: 1500px) {
    .service-section {
      padding: 100px 150px;
    }
  }

  @media screen and (min-width: 1600px) {
    .service-section {
      padding: 100px 200px;
    }
  }

  @media screen and (min-width: 2000px) {
    .service-section {
      padding: 100px 200px;
    }
  }

  @media screen and (max-width: 768px) {
    .service-section {
      padding: 20px;
      h4 {
        margin-bottom: 20px;
      }
      .mobile-left {
        margin-top: 20px;
      }
      .service-flex-wrapper {
        border-radius: 10px;
      }
      .flex-col {
        width: 100%;
        border-right: none;
      }
      .mobile-flex-start {
        align-items: flex-start;
      }

      .mobile-border-bottom {
        border-bottom: 1px solid #b3baad;
      }
    }
  }
`;
